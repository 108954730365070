import { IRetarget, ProductType } from '@vfit/business-data-access';
import { IActionCustom } from '@vfit/shared/models';
import { DXL } from '@vfit/business-data-access';
import { IProductMoreInfo, IProductTag } from '../../widgetSliderProductsHub.models';
import { ILandingPrive } from '../../../WidgetProductHeroBanner/widgetProductHeroBanner.models';

export interface IProductCardProps {
  product: IProductCard;
  large?: boolean;
  hideActions?: boolean;
  hideSpecs?: boolean;
  hideSpecsLockIn?: boolean;
  isLoading?: boolean;
  noMargin?: boolean;
  onActive?: () => void;
  setSpecHeight?: (specHeight: number) => void;
  cardIndex?: number;
  isLockIn?: boolean;
  haveMarketingLabel?: boolean;
  productVersion?: string;
  handleClickPostCoverage?: (
    param: IActionCustom,
    product?: IProductCard,
    desc?: string,
    version?: string
  ) => void;
}

export interface IProductCard {
  overrideCategory?: string;
  offerName?: string;
  frontLabel?: string;
  bottomLabel?: string;
  additionalOfferDetails?: IProductMoreInfo;
  offerSpecs?: IProductMoreInfo[];
  offerId?: string;
  isEditorialCard?: string;
  taggingProductPrice?: string;
  image?: string;
  price?: string;
  actions?: IActionCustom[];
  pills?: IProductTag[];
  tagLine?: string;
  priceDescription?: string;
  shortDescription?: string;
  unaTantumPriceDescription?: string;
  retarget?: IRetarget;
  version?: string;
  landingprive?: ILandingPrive;
  title?: string;
  taggingProductName?: string;
  timeDiscount?: string;
  timeDiscountLabels?: ITimeDiscountLabels;
}

export interface ITimeDiscountLabels {
  timeDiscountTag?: string;
  timeDiscountDetails?: string;
  timeDiscountTagForDevicePages?: string;
}

export const supportRegisterNumberApi: Partial<{ [key in ProductType]: string }> = {
  FIXED: 'getFixedLineCommonWhatsapp',
  VOCE: 'getMobileLineCommonWhatsapp',
  DATI: 'getDatiLineCommonWhatsapp',
};
