import React, { Children, useContext, useEffect, useRef, useState } from 'react';
import { Check } from '@vfit/shared-icons';
import { Button, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { colors, fonts } from '@vfit/shared/themes';
import { Pills, VfModal } from '@vfit/shared/components';
import { IActionCustom, IActionType } from '@vfit/shared/models';
import {
  TrackingPageContext,
  getEventLabel,
  RetargetContext,
  ITimerStatus,
  HubPostCoverageContext,
} from '@vfit/business-data-access';
import {
  useVolaUtils,
  usePostCreateShoppingCartMobile,
  CTC_POSITION,
} from '@vfit/business-data-access';
import {
  AppMobile,
  getActionUrlByActionType,
  getVariantByActionStyle,
} from '@vfit/shared/data-access';
import WhatsappModal from '../../../WhatsappModal/whatsappModal';
import CoverageToolModal from '../../../CoverageToolModal/coverageToolModal';
import { IProductMoreInfo } from '../../widgetSliderProductsHub.models';
import { useCardFunctionality } from './productCard.hook';
import { IProductCardProps } from './productCard.models';
import {
  ModalContent,
  ModalTitle,
  ProductCardBottom,
  ProductCardBottomContainer,
  ProductCardBottomContainerText,
  ProductCardContainer,
  ProductCardOfferLabel,
  ProductCardSpecContainer,
  ProductCardSpecItem,
  ProductCardSpecItemAction,
  ProductCardSpecText,
  ProductCardSpecTitle,
  ProductCardTopImage,
  ProductCardTopImageContent,
  DivImgContainer,
  H3Header,
  H2Header,
} from './productCard.style';
import ProductDetail from '../../../ProductDetail/productDetail';
import ProductCardWrapper from '../../../ProductCardWrapper/productCardWrapper';
import SupportRegisterNumber from 'libs/shared/components/src/lib/SupportRegisterNumber/supportRegisterNumber';

const ProductCard = ({
  product,
  haveMarketingLabel,
  large,
  noMargin,
  productVersion,
  handleClickPostCoverage,
}: IProductCardProps) => {
  const { link, currentDataLayer } = useContext(TrackingPageContext);
  const { setOfferTimer } = useContext(RetargetContext);
  const specRef = useRef<HTMLDivElement | null>(null);
  const {
    offerName,
    frontLabel,
    bottomLabel,
    additionalOfferDetails,
    offerSpecs,
    image,
    price,
    actions,
    pills,
    tagLine,
    offerId,
    priceDescription,
    unaTantumPriceDescription,
    shortDescription,
    retarget,
    version,
    timeDiscount,
    timeDiscountLabels,
    taggingProductPrice,
    overrideCategory,
    taggingProductName,
  } = product;

  const {
    handleClick,
    isShowCoverageTool,
    setIsShowCoverageTool,
    isShowWhatsapp,
    setIsShowWhatsapp,
    showCheckout,
    modalDetails,
    setModalDetails,
    registerNumber,
    isShowregisterNumber,
    setIsShowregisterNumber,
  } = useCardFunctionality();
  const { getVolaUrl, createUrlDesc } = useVolaUtils();
  const { checkRetargetActive } = useContext(RetargetContext);
  const { isHubPostCoverage } = useContext(HubPostCoverageContext);

  const { data } = usePostCreateShoppingCartMobile(offerId!, showCheckout);

  const filteredActions =
    actions && actions[0]?.isForApp
      ? actions.filter(({ isForApp }) => isForApp === `${AppMobile.checkIsApp()}`)
      : actions;

  useEffect(() => {
    const checkoutAction = product?.actions?.find(
      (a) => a?.type == IActionType.CHECKOUT_MODAL_MOBILE
    );
    if (data && checkoutAction) {
      window.open(
        `${checkoutAction?.url}${data?.id}`,
        checkoutAction?.isBlank === 'true' ? '_blank' : '_self'
      );
    }
  }, [data]);

  const [modal, setModal] = useState({
    show: false,
    text: '',
    title: '',
  });

  const renderPill = () => {
    if (timeDiscount && timeDiscountLabels?.timeDiscountTag) {
      return (
        <Pills pills={[timeDiscountLabels?.timeDiscountTag]} variant={colors.$00697c} opacity={1} />
      );
    }
    if (checkRetargetActive({ productId: offerId!, version }) === ITimerStatus.ACTIVE) {
      return retarget?.label ? (
        <Pills pills={[retarget.label]} variant={colors.$00697c} opacity={1} />
      ) : (
        <h6>{'\u00A0'}</h6>
      );
    }
    return <h6>{frontLabel || '\u00A0'}</h6>;
  };

  const renderPrice = () => (
    <>
      {renderPill()}
      {price && (
        <div className="productTitle">
          <CustomText text={`<div class="price">${price}</div>`} textAlign="left" />
        </div>
      )}
      <h4>{bottomLabel || '\u00A0'}</h4>
      {priceDescription && (
        <div className="productDescription">
          <CustomText
            text={priceDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      {unaTantumPriceDescription && (
        <div className="productDescription">
          <CustomText
            text={unaTantumPriceDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      {shortDescription && (
        <div className="productDescription">
          <CustomText
            text={shortDescription}
            textAlign="left"
            fontFamily={fonts.regular}
            size={18}
            lineHeight={24}
          />
        </div>
      )}
      <div className="additionalOfferDetails">
        {additionalOfferDetails ? renderInfo(additionalOfferDetails) : '\u00A0'}
      </div>
    </>
  );

  const renderInfo = (info: IProductMoreInfo, icon = false) => {
    const { title, subtitle, enableMoreInfo, moreInfoContentHtml, moreInfoTitle } = info;

    return (
      <ProductCardSpecContainer>
        <ProductCardSpecItem>
          {icon && (
            <div>
              <Check />
            </div>
          )}
          <ProductCardSpecText>
            {title && <ProductCardSpecTitle>{title}</ProductCardSpecTitle>}
            {subtitle && <CustomText text={subtitle} textAlign="left" />}
          </ProductCardSpecText>
        </ProductCardSpecItem>
        {enableMoreInfo === 'true' && (
          <div
            role="presentation"
            onClick={() =>
              setModal({
                show: true,
                text: moreInfoContentHtml || '',
                title: moreInfoTitle || '',
              })
            }
          >
            <ProductCardSpecItemAction>+</ProductCardSpecItemAction>
          </div>
        )}
      </ProductCardSpecContainer>
    );
  };

  const onTrack = (btn: IActionCustom) => {
    if (
      btn?.type == IActionType.CALL_ME_NOW ||
      btn?.type == IActionType.LOCAL_URL ||
      btn?.type == IActionType.CHECKOUT_MODAL_MOBILE
    ) {
      link(
        {
          ...currentDataLayer?.hubDataLayer,
          link_name: `${currentDataLayer?.hubDataLayer?.page_name}:${
            taggingProductName + getEventLabel(btn?.type)
          }`,
        },
        product?.offerId
      );
    }
  };

  const onHandleClick = (btn: IActionCustom, desc?: string) => {
    if (btn?.type == IActionType.CHECKOUT_MODAL_MOBILE)
      setOfferTimer(version!, product?.offerId || '', retarget);
    if (isHubPostCoverage && handleClickPostCoverage)
      handleClickPostCoverage(btn, product, desc, productVersion);
    else handleClick(btn, product, desc);
  };

  const renderButtons = () => (
    <div
      style={{
        marginBottom: 32,
      }}
    >
      {Children.toArray(
        filteredActions?.map((btn) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <Button
              onClick={() => {
                const desc = createUrlDesc({
                  position: CTC_POSITION.PRODUCT_CARD,
                  productName: taggingProductName,
                  productPrice: taggingProductPrice,
                });
                onTrack(btn);
                !getActionUrlByActionType(btn) && onHandleClick(btn, desc);
              }}
              href={getActionUrlByActionType(btn)}
              target={
                getActionUrlByActionType(btn) && btn?.isBlank === 'true' ? '_blank' : AppMobile.checkIsApp() && getActionUrlByActionType(btn) ? '_self' : undefined
              }
              variant={getVariantByActionStyle(Number(btn?.style))}
              name={`action_offerslider_${btn.type}`}
            >
              {btn.title}
            </Button>
          </div>
        ))
      )}
    </div>
  );

  const renderSpecs = () => (
    <div
      ref={specRef}
      style={{
        marginBottom: 32,
      }}
    >
      {Children.toArray(
        offerSpecs &&
          offerSpecs.length > 0 &&
          offerSpecs.map((spec) => <div>{renderInfo(spec, true)}</div>)
      )}
    </div>
  );

  const getPdpDetails = () => (
    <ProductDetail
      product={{
        id: product.offerId || '',
        title: product?.offerName || '',
        price: product?.price,
        description: product?.priceDescription || '',
      }}
      productOffer={product}
      showPopupTitle
    />
  );

  return (
    <>
      {haveMarketingLabel && (
        <ProductCardOfferLabel haveLabel={!!tagLine}>
          {tagLine && <span>{tagLine}</span>}
        </ProductCardOfferLabel>
      )}
      <ProductCardContainer noMargin={noMargin} haveOfferLabel={haveMarketingLabel}>
        <ProductCardTopImage large={large}>
          <Pills pills={pills?.map((p) => p.keyword) || []} />
          <ProductCardTopImageContent>
            {overrideCategory && <H2Header>{overrideCategory}</H2Header>}
            <H3Header>{offerName}</H3Header>
          </ProductCardTopImageContent>
          <DivImgContainer absolute>
            <ImageAtoms
              nameCard="topImage"
              image={image}
              alt={`${overrideCategory || ''} ${offerName}`}
            />
          </DivImgContainer>
        </ProductCardTopImage>
        <ProductCardBottom>
          <ProductCardBottomContainer>
            <ProductCardBottomContainerText>{renderPrice()}</ProductCardBottomContainerText>
            <div>{renderButtons()}</div>
            <div>{renderSpecs()}</div>
          </ProductCardBottomContainer>
        </ProductCardBottom>
        <VfModal
          height={563}
          isOpen={modal.show}
          handleClose={() => setModal({ show: false, text: '', title: '' })}
        >
          <>
            {modal.title && (
              <ModalTitle>
                <CustomText fontFamily={fonts.exbold} textAlign="left" text={modal.title} />
              </ModalTitle>
            )}
            <ModalContent>
              <CustomText size={18} lineHeight={24} textAlign="left" text={modal.text} />
            </ModalContent>
          </>
        </VfModal>
        <VfModal height={540} isOpen={isShowWhatsapp} handleClose={() => setIsShowWhatsapp(false)}>
          <WhatsappModal product={product} urlVola={getVolaUrl(product)} />
        </VfModal>
        {
          <VfModal
            height={540}
            isOpen={isShowregisterNumber}
            handleClose={() => setIsShowregisterNumber(false)}
          >
            <SupportRegisterNumber
              {...registerNumber}
              offerId={offerId}
              offerName={offerName}
              goToNextStep={() => {
                setIsShowregisterNumber(false);
                setIsShowWhatsapp(true);
              }}
            />
          </VfModal>
        }

        <VfModal
          height={563}
          isOpen={modalDetails.show}
          handleClose={() => setModalDetails({ show: false })}
        >
          {getPdpDetails()}
        </VfModal>
        <CoverageToolModal
          backgroundImage={product.image}
          isOpen={isShowCoverageTool}
          product={product}
          handleClose={() => setIsShowCoverageTool(false)}
        />
      </ProductCardContainer>
    </>
  );
};

export default ProductCardWrapper(ProductCard);

