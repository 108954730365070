import { useContext, useEffect, useRef, useState } from 'react';
import { TrackingPageContext, getEventLabel } from '@vfit/business-data-access';
import { CTC_POSITION, useVolaUtils } from '@vfit/business-data-access';
import {
  ButtonSlideCTA,
  ContainerTopProduct,
  ImageDivider,
  CustomText,
  ButtonSlideCustom,
  Button,
} from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { VfModal, StickyOffer } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import { IActionCustom, IActionStyle, IActionType } from '@vfit/shared/models';
import { useDeviceType, useViewportHeight } from '@vfit/shared/hooks';
import { getActionUrlByActionType, getVariantByActionStyle } from '@vfit/shared/data-access';
import SlideProduct from './components/SlideProduct/slideProduct';
import {
  ButtonDesc,
  DisclaimerContainer,
  ContainerDescription,
  ActionContainer,
} from './topProduct.style';
import { ITopProduct, IType } from './topProduct.model';
import CoverageToolModal from '../CoverageToolModal/coverageToolModal';
import { useCardFunctionality } from '../WidgetSliderProductsHub/components/ProductCard/productCard.hook';
import WhatsappModal from '../WhatsappModal/whatsappModal';
import ProductDetail from '../ProductDetail/productDetail';
import SupportRegisterNumber from 'libs/shared/components/src/lib/SupportRegisterNumber/supportRegisterNumber';

const TopProduct = ({
  product,
  disclaimer,
  description,
  divider,
  disableNavigation,
  offerDetails,
  createShoppingCart,
  indexWidget,
}: ITopProduct) => {
  const refActionsButtons = useRef<HTMLDivElement>(null);
  const [directionSlideStickyOffer, setDirectionSlideStickyOffer] = useState<
    'upward' | 'downward' | undefined
  >();
  const { headerHeight } = useViewportHeight();
  const { isMobile } = useDeviceType();
  const productContentRef = useRef<HTMLDivElement | null>(null);
  const [modal, setModal] = useState<any>({
    show: false,
    type: IType.TEXT,
  });

  const {
    handleClick,
    isShowCoverageTool,
    setIsShowCoverageTool,
    isShowWhatsapp,
    setIsShowWhatsapp,
    showCheckout,
    registerNumber,
    isShowregisterNumber,
    setIsShowregisterNumber,
  } = useCardFunctionality();
  const { getVolaUrl, createUrlDesc } = useVolaUtils();
  const { link, currentDataLayer } = useContext(TrackingPageContext);

  useEffect(() => {
    if (showCheckout === product.offerId) {
      createShoppingCart?.();
    }
  }, [showCheckout]);

  useEffect(() => {
    if (refActionsButtons.current) {
      const observer = new IntersectionObserver((entry) => {
        entry[0].isIntersecting
          ? setDirectionSlideStickyOffer('downward')
          : setDirectionSlideStickyOffer('upward');
      });
      observer.observe(refActionsButtons.current!);
    }
  }, [refActionsButtons.current]);

  const resetModal = () => {
    setModal({
      show: false,
      type: IType.TEXT,
      text: '',
      title: '',
      disableOrganizeText: false,
    });
  };

  const productDetailButton = (): JSX.Element => {
    const showDetail = () => {
      setModal({
        show: true,
        disableOrganizeText: false,
        type: IType.PRODUCT,
      });
    };
    return (
      <ButtonDesc>
        <ButtonSlideCTA>
          <ButtonSlideCustom
            onClick={showDetail}
            style={offerDetails?.style}
            label={offerDetails?.title}
            name="action_detail_top_product"
          />
        </ButtonSlideCTA>
      </ButtonDesc>
    );
  };

  const scrollToContent = () => {
    if (productContentRef?.current) {
      productContentRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    } else if (typeof indexWidget === 'number') {
      const targetElement = document.querySelector(`div[data-widget-id="${indexWidget + 1}"]`);
      const viewportYDelta = targetElement?.getBoundingClientRect().top || 0;
      const targetElementY = window.scrollY + viewportYDelta - headerHeight;
      window.scrollTo({ top: targetElementY, behavior: 'smooth' });
    }
  };

  const modalCustomFooter = () => (
    <ActionContainer>
      {product?.actions?.map((cta) => (
        <div>
          <Button
            onClick={() => {
              const desc = createUrlDesc({
                position: CTC_POSITION.PRODUCT_DETAIL,
                productName: product.taggingProductName || product.title,
                productPrice: product.taggingProductPrice,
              });
              !getActionUrlByActionType(cta) && handleActivationProduct(cta, desc);
            }}
            href={getActionUrlByActionType(cta)}
            target={getActionUrlByActionType(cta) && cta?.isBlank === 'true' ? '_blank' : undefined}
            variant={getVariantByActionStyle(Number(cta?.style))}
            name={`action_product_details_${cta?.type}`}
          >
            {cta?.title}
          </Button>
        </div>
      ))}
    </ActionContainer>
  );

  const getSticky = () => {
    const cta = product.actions?.find((item) => item?.style == IActionStyle.PRIMARY);
    return (
      product.priceResponsive &&
      cta && (
        <StickyOffer
          slideBanner={isMobile ? 'upward' : directionSlideStickyOffer}
          id="sticky-offer-product"
          title={product.priceResponsive}
          description={offerDetails?.title || ''}
          ctaActivation={{
            action: () => {
              const desc = createUrlDesc({
                position: CTC_POSITION.STICKY_OFFER,
                productName: product.taggingProductName || product.title,
                productPrice: product.taggingProductPrice,
              });
              !getActionUrlByActionType(cta) && handleClick(cta!, product, desc);
            },
            label: cta?.title || '',
            actionNavigationUrl: getActionUrlByActionType(cta),
            isBlank: cta?.isBlank,
            type: cta?.type,
          }}
          content={!product.hideDetails ? getPdpDetails() : undefined}
        />
      )
    );
  };

  const getPdpDetails = () => (
    <ProductDetail
      product={{
        id: product.offerId || '',
        title: product?.title || '',
        price: product?.price,
        description: product?.priceDescription || '',
      }}
      productOffer={product}
      showPopupTitle
      showHelps={isMobile}
    />
  );

  const handleActivationProduct = (action?: IActionCustom, desc?: string) => {
    if (
      action?.type == IActionType.CALL_ME_NOW ||
      action?.type == IActionType.CHECKOUT_MODAL_MOBILE
    ) {
      link(
        {
          ...currentDataLayer?.pdpDataLayer,
          link_name: `${currentDataLayer?.pdpDataLayer?.page_name}${getEventLabel(action?.type)}`,
        },
        product?.offerId
      );
    }
    action && handleClick(action, product, desc);
  };

  return (
    <>
      <SlideProduct
        disableNavigation={disableNavigation}
        onActiveProduct={handleActivationProduct}
        product={product}
        onButtonScroll={scrollToContent}
        ref={refActionsButtons}
      />
      {disclaimer && (
        <ContainerTopProduct ref={productContentRef}>
          <Fade direction="up" triggerOnce>
            <ContainerDescription>
              <CustomText
                text={disclaimer}
                textAlign="left"
                size={52}
                lineHeight={70}
                sizeMobile={35}
                lineHeightMobile={45}
              />
            </ContainerDescription>
            {offerDetails && !isMobile && productDetailButton()}
            {description && (
              <DisclaimerContainer>
                <CustomText
                  textAlign="left"
                  text={description}
                  lineHeight={22}
                  size={16}
                  fontFamily={fonts.light}
                />
              </DisclaimerContainer>
            )}
          </Fade>
        </ContainerTopProduct>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={resetModal}
        customFooter={modal.type === IType.PRODUCT ? modalCustomFooter : undefined}
      >
        {getPdpDetails()}
      </VfModal>
      <VfModal height={540} isOpen={isShowWhatsapp} handleClose={() => setIsShowWhatsapp(false)}>
        <WhatsappModal product={product} urlVola={getVolaUrl(product)} />
      </VfModal>
      {
          <VfModal
            height={540}
            isOpen={isShowregisterNumber}
            handleClose={() => setIsShowregisterNumber(false)}
          >
            <SupportRegisterNumber
              {...registerNumber}
              offerId={product?.offerId}
              offerName={product?.offerLabel}
              goToNextStep={() => {
                setIsShowregisterNumber(false);
                setIsShowWhatsapp(true);
              }}
            />
          </VfModal>
        }
      {getSticky()}
      <CoverageToolModal
        backgroundImage={product.image}
        isOpen={isShowCoverageTool}
        product={product}
        handleClose={() => setIsShowCoverageTool(false)}
      />
    </>
  );
};

export default TopProduct;

