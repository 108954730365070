import { IPlaceholder, useGetProductEcommerceCatalog } from '@vfit/business-data-access';
import { useContext, useEffect, useRef, useState } from 'react';
import { HubPostCoverageContext } from '@vfit/business-data-access';
import { IBusinessSliderProductsHub } from './widgetSliderProductsHub.models';
import { formatProducts } from './widgetSliderProductsHub.utils';
import slugify from 'slugify';
import { useRouter } from 'next/router';

const useWidgetSliderProductHub = (
  widget: IBusinessSliderProductsHub,
  placeholders: IPlaceholder[]
) => {

  const { query } = useRouter();
  const { elements } = widget;
  const { isHubPostCoverage, hubPostCoverageElements } = useContext(HubPostCoverageContext);

  const currentElements =
    isHubPostCoverage && hubPostCoverageElements.length > 0 ? hubPostCoverageElements : elements;

  const { catalog } = useGetProductEcommerceCatalog(currentElements);
  // This Ref is for scrolling to the Weekly offer section
  const weekOfferRef = useRef<HTMLDivElement | null>(null);

  // This function for scolling to specific section
  const scrollToSection = () => {
    if (weekOfferRef.current) {
      weekOfferRef.current.scrollIntoView({ behavior: 'smooth' });
    }

  };

  useEffect(() => {
    const weeklyOffer = slugify(String(query?.["wo"]), {
      lower: true, // convert to lower case, defaults to `false`
    });

    if(weeklyOffer === "true"){
      scrollToSection();
    }
  }, [query])
  


  return {
    title: widget.titleHtml || '',
    products: formatProducts(currentElements, placeholders, catalog),
    ref :weekOfferRef 
  };
};

export { useWidgetSliderProductHub };

