import { IActionCustom, IActionType, IErrorFormApi, ISubmitOutput } from '@vfit/shared/models';
import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { AuthenticationContext, IModuleRegisterNumber, ISupportModules, TrackingPageContext } from '@vfit/business-data-access';
import { IAddress, IEcommerceCatalogResponse, useVolaUtils } from '@vfit/business-data-access';
import { useQueryClient } from 'react-query';
import {
  IGetAllCmsForm,
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  openLeadModal,
} from '@vfit/shared/data-access';
import { IProductCard, supportRegisterNumberApi } from './productCard.models';
import { IUseCoverageToolFlowPayload } from '../../../CoverageToolModal/coverageTool.hooks';

const useCardFunctionality = () => {
  const { descriptionVolaUrl ,pageType } = useContext(TrackingPageContext);
  const { formatUrlVola, openPopupVola, formatDynamicVolaUrl } = useVolaUtils();
  const queryClient = useQueryClient();
  const { authType } = useContext(AuthenticationContext);
  let registerNumber:IModuleRegisterNumber | undefined = {} ;

  if(pageType&&supportRegisterNumberApi[pageType]&&queryClient.getQueryData(supportRegisterNumberApi[pageType])){
    const {whatsapp} = queryClient.getQueryData(supportRegisterNumberApi[pageType]) as ISupportModules;
    registerNumber = whatsapp?.registerNumber;
  }

  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const [isShowWhatsapp, setIsShowWhatsapp] = useState(false);
  const [isShowregisterNumber, setIsShowregisterNumber] = useState(false);
  const [showCheckout, setShowCheckout] = useState<string>('');
  const [modalDetails, setModalDetails] = useState({
    show: false,
  });
  const [postCoverageData, setPostCoverageData] = useState<IUseCoverageToolFlowPayload>({
    onConfirmAddress: false,
    product: [],
    address: {},
  });

  const { push } = useRouter();

  const handleClick = (
    param: IActionCustom,
    product?: IProductCard,
    desc?: string,
    version?: string
  ) => {
    const catalog = queryClient.getQueryData([
      product?.offerId,
      'ecommerceCatalogCoreOffer',
    ]) as IEcommerceCatalogResponse;
    const address = JSON.parse(localStorage.getItem('valid_address') || '{}') as IAddress;

    const type = param?.type ? +param.type : 0;
    switch (type) {
      case IActionType.CUSTOM_URL:
        if (param?.url) {
          window.open(param?.url, param?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.LOCAL_URL:
        if (param?.url) push(param?.url);
        break;
      case IActionType.COVERAGE_TOOL:
        setIsShowCoverageTool(true);
        break;
      case IActionType.WHATSAPP_URL:
       registerNumber?.enableRegisterNumber =='true' ? setIsShowregisterNumber(true) :setIsShowWhatsapp(true);
        break;
      case IActionType.CALL_ME_NOW:
        if (param.url) {
          if (descriptionVolaUrl && desc)
            openPopupVola(formatDynamicVolaUrl(param?.url, desc), 'vola-ctc');
          else openPopupVola(formatUrlVola(param?.url, product!), 'vola-ctc');
        }
        break;
      case IActionType.CHECKOUT_MODAL_MOBILE:
        setShowCheckout(product?.offerId || '');
        break;
      case IActionType.POPUP_DETAIL:
        setModalDetails({
          show: true,
        });
        break;
      case IActionType.POST_COVERAGE:
        queryClient.setQueryData('coverageToolProduct', product);
        setPostCoverageData({
          onConfirmAddress: true,
          product: [
            {
              productId: product?.offerId || '',
              productType: catalog?.productInfo?.[0]?.productType,
              productOfferingId: catalog?.productInfo?.[0]?.productOfferingId,
              version,
            },
          ],
          address: {
            addressCode: address?.addressCode,
            streetName: address?.streetName,
            streetNr: address?.streetNr,
            city: address?.city,
            stateOrProvince: address?.stateOrProvince,
            postcode: address?.postcode,
          },
        });
        break;
      case IActionType.LEAD_MODAL:
        if (queryClient) {
          const allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
          // form name
          const ref = param?.url || '';
          const forms = allLeads?.[ref].forms;
          if (forms) {
            openLeadModal({
              form: forms,
              onSuccess: (submitOutput: ISubmitOutput) =>
                manageSuccessLeadPlatform(submitOutput, push, queryClient),
              onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
                manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
              trackingOption: {
                visitor_type: authType,
              },
            });
          }
        }
        break;
      default:
        break;
    }
  };

  return {
    handleClick,
    isShowCoverageTool,
    setIsShowCoverageTool,
    isShowWhatsapp,
    setIsShowWhatsapp,
    showCheckout,
    modalDetails,
    setModalDetails,
    postCoverageData,
    setPostCoverageData,
    registerNumber,
    isShowregisterNumber, 
    setIsShowregisterNumber
  };
};

export { useCardFunctionality };

